/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const PlayfairDisplay_400Regular = require('./PlayfairDisplay_400Regular.ttf');
export const PlayfairDisplay_500Medium = require('./PlayfairDisplay_500Medium.ttf');
export const PlayfairDisplay_600SemiBold = require('./PlayfairDisplay_600SemiBold.ttf');
export const PlayfairDisplay_700Bold = require('./PlayfairDisplay_700Bold.ttf');
export const PlayfairDisplay_800ExtraBold = require('./PlayfairDisplay_800ExtraBold.ttf');
export const PlayfairDisplay_900Black = require('./PlayfairDisplay_900Black.ttf');
export const PlayfairDisplay_400Regular_Italic = require('./PlayfairDisplay_400Regular_Italic.ttf');
export const PlayfairDisplay_500Medium_Italic = require('./PlayfairDisplay_500Medium_Italic.ttf');
export const PlayfairDisplay_600SemiBold_Italic = require('./PlayfairDisplay_600SemiBold_Italic.ttf');
export const PlayfairDisplay_700Bold_Italic = require('./PlayfairDisplay_700Bold_Italic.ttf');
export const PlayfairDisplay_800ExtraBold_Italic = require('./PlayfairDisplay_800ExtraBold_Italic.ttf');
export const PlayfairDisplay_900Black_Italic = require('./PlayfairDisplay_900Black_Italic.ttf');
